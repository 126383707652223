import core from "@/core";
import AbstractTableService from "@/services/core/abstract-table.service";
import { MediaJobModel, MediaStatusType } from "@/models/media/media.model";

class MediaJobService extends AbstractTableService<MediaJobModel> {
  constructor() {
    super("/api/v1/media/job", "mediaJob");
  }

  updateStatus(id: number, status: MediaStatusType): Promise<MediaJobModel> {
    const strParam = JSON.stringify({
      status: status,
    });
    return new Promise((resolve, reject) => {
      core.http
        .putJson(`/api/v1/media/job/${id}/status`, strParam)
        .then((data) => {
          //console.log('result: ', data);
          if (data[this.itemKey]) {
            resolve(data[this.itemKey]);
          } else {
            reject({ message: "not found item" });
          }
        })
        .catch((reason) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }
}

export default new MediaJobService();
