
import mixins from "vue-typed-mixins";
import MixinsPageForm from "@/mixins/page-form";
import store from "@/store";
import core from "@/core";

export default mixins(MixinsPageForm).extend({
  name: "Settings",
  components: {},
  data: () => ({
    app: store.state.app,
    auth: store.state.auth,
    processing: false,
    settings: {
      notification: false,
    },
  }),
  computed: {
    isManagerRoleHigher() {
      return this.$store.getters["auth/isManagerRoleHigher"];
    },
    isAdminRole() {
      return this.$store.getters["auth/isAdminRole"];
    },
  },
  mounted() {
    const mobileSetting = this.auth.mobileSetting;
  },
  watch: {
    "auth.mobileSetting"(mobileSetting) {
      if (mobileSetting) {
        this.processing = false;
      }
    },
  },
  methods: {
    async logout() {
      const result = await core.alert.show({
        title: "확인",
        body: "현재 인증된 정보가 해제됩니다.<br>계속 진행하시겠습니까?",
        showCancelButton: true,
      });
      if (result === "confirm") {
        await this.$router.push("/logout");
      }
    },
    // updateCustomerReceiveCall() {
    //   if (!this.processing) {
    //     this.processing = true;
    //     const mobileSetting = this.$store.state.auth.mobileSetting as MobileSetting | null;
    //     // console.log("mobileSetting : ", mobileSetting);
    //     if (mobileSetting != null && mobileSetting.customerReceiveCall) {
    //       // 고객 수신 전화 설정 변경
    //       mobileSetting.customerReceiveCall.enable = !mobileSetting.customerReceiveCall.enable;
    //       core.mobile.call({
    //         cmd: "updateCustomerReceiveCall",
    //         value: mobileSetting.customerReceiveCall,
    //       });
    //     } else {
    //       this.processing = false;
    //     }
    //   }
    // },
  },
});
