import { IdUpdateModel } from "@/models/core/type.model";
import { YnType } from "@/types";
import Hls from "hls.js";

export enum PlatformType {
  AFREECA = "AFREECA",
  POPKON = "POPKON",
  PANDA = "PANDA",
  CHATURBATE = "CHATURBATE",
}

export enum MediaStatusType {
  NONE = "NONE",
  WAIT = "WAIT", // 작업대기
  REQUEST = "REQUEST", // 작업요청
  PROCESSING = "PROCESSING", // 작업중
  FAILED = "FAILED", // 실패
  CANCEL = "CANCEL", // 취소중
  CANCELING = "CANCELING", // 취소
  COMPLETE = "COMPLETE", // 완료
}

export enum VideoType {
  HLS = "HLS",
  MP4 = "MP4",
}

export interface MediaJobModel extends IdUpdateModel {
  title: string;
  url: string;
  cookie: string;
  origin: string | null;
  referer: string | null;
  description: string | null;
  platform: PlatformType;
  processingYn: YnType;
  status: MediaStatusType;
  statusAt: string;
  changeStatus: MediaStatusType | null;
  changeStatusAt: string | null;
  videoType: VideoType | null;
  videoUri: string | null;
  recordingStartAt: string | null;
  recordingEndAt: string | null;
  result: string;
}

export interface PlayVideoModel {
  play: boolean;
  url: string;
  hlsPlayer: Hls | null;
  job: MediaJobModel | null;
}
