
import mixins from "vue-typed-mixins";
import AppBarProps from "@/components/core/AppBarProps.vue";
import core from "@/core";
import store from "@/store";
import { MenuType } from "@/router";
import Constant from "@/store/constant";
import MixinsCommon from "@/mixins/single/common";

export default mixins(AppBarProps, MixinsCommon).extend({
  name: "AppBar",
  data: () => ({
    menuList: [],
    app: store.state.app,
    auth: store.state.auth,
    menuItems: {
      selected: null as any,
      // list: MenuItems,
      list: [] as any,
    },
    selectedItem: 0,
  }),
  created() {
    if (this.app.isMobileSize) {
      this.app.showNav = false;
    } else {
      this.app.showNav = true;
    }
  },
  computed: {
    isManagerRoleHigher() {
      return this.$store.getters["auth/isManagerRoleHigher"];
    },
    isAdminRole() {
      return this.$store.getters["auth/isAdminRole"];
    },
    MenuType() {
      return MenuType;
    },
    Constant() {
      return Constant;
    },
    title() {
      return this.$store.getters["topToolbar/title"];
    },
    dark() {
      return this.$store.getters["topToolbar/dark"];
    },
    clazz() {
      return this.$store.getters["topToolbar/clazz"];
    },
    elevation() {
      return this.$store.getters["topToolbar/elevation"];
    },
    color() {
      return this.$store.getters["topToolbar/color"];
    },
  },
  methods: {
    toggleMenu() {
      if (this.app.showNav == null) this.app.showNav = true;
      this.app.showNav = !this.app.showNav;
    },
    goBack() {
      this.$router.back();
    },
    async logout() {
      const result = await core.alert.show({
        title: "확인",
        body: "현재 인증된 정보가 해제됩니다.<br>계속 진행하시겠습니까?",
        showCancelButton: true,
      });
      if (result === "confirm") {
        await this.$router.push("/logout");
      }
    },
  },
});
