
import mixins from "vue-typed-mixins";
import MixinsPageForm from "@/mixins/page-form";
import store from "@/store";
import HomeComponent from "@/components/home/HomeComponent.vue";

export default mixins(MixinsPageForm).extend({
  name: "Home",
  components: { HomeComponent },
  data: () => ({
    app: store.state.app,
    extensionHeight: 65,
    bodyMaxHeight: 0,
  }),
  computed: {
    isManagerRoleHigher() {
      return this.$store.getters["auth/isManagerRoleHigher"];
    },
    isAdminRole() {
      return this.$store.getters["auth/isAdminRole"];
    },
  },
  created() {
    this.windowResize();
  },
  watch: {
    "app.size"() {
      this.windowResize();
    },
    "app.webSocketConnected"() {
      this.windowResize();
    },
  },
  methods: {
    windowResize() {
      const connected = this.app.webSocketConnected;
      this.bodyMaxHeight = this.app.size.height + (connected ? 0 : -30);
    },
  },
});
