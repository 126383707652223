import korea from "vee-validate/dist/locale/ko.json";

class Constant {
  appName = "DK";
  validate = {
    language: {
      korea: korea,
    },
  };
  kakao = {
    javascriptKey: "f329627384efbf9492f27ff161b4ee33",
    restApiKey: "57aa520411e94a833bb9770ee3bb1828",
  };
  url = "http://127.0.0.1:3000";
  // videoServerUrl = "https://hls.khwan.net"
  videoServerUrl = "https://hls.khwan.net"
  param = {
    // login: ["shareCalendarEventUuid", "companyInvitationCode"],
    login: [],
  };
  // iosStoreUrl = "http://itunes.apple.com/kr/app/kupang-coupang/id454434967?mt=8";
  iosStoreUrl = "http://itunes.apple.com/kr/app/";
}

export default new Constant();
