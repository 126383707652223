var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-app',[(_vm.auth.user)?[(!_vm.app.webSocketConnected)?_c('v-system-bar',{attrs:{"dark":_vm.dark,"app":"","color":"grey darken-3","height":"30"}},[_c('v-spacer'),(_vm.app.webSocketConnected)?[_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-wifi")])]:[_c('v-icon',[_vm._v("mdi-wifi-off")]),_vm._v(" 서버 연결중... ")]],2):_vm._e()]:_vm._e(),(
      _vm.prop.menuType === _vm.MenuType.HOME ||
      _vm.prop.menuType === _vm.MenuType.AUTO_NAV_BOTTOMBAR ||
      _vm.prop.menuType === _vm.MenuType.AUTO_NAV_BACK ||
      _vm.prop.menuType === _vm.MenuType.NAVIGATION ||
      _vm.prop.menuType === _vm.MenuType.BACK ||
      _vm.prop.menuType === _vm.MenuType.TITLE ||
      _vm.prop.menuType === _vm.MenuType.BOTTOMBAR
    )?_c('app-bar',{attrs:{"prop":_vm.prop}}):_vm._e(),_c('vue-page-stack',[_c('router-view',{key:_vm.$route.fullPath})],1),(_vm.prop.menuType === _vm.MenuType.BOTTOMBAR || _vm.prop.menuType === _vm.MenuType.AUTO_NAV_BOTTOMBAR)?_c('app-bottom-bar',{attrs:{"prop":_vm.prop}}):_vm._e(),(_vm.app.modal.alert.visible)?_c('alert-modal',{attrs:{"properties":_vm.app.modal.alert}}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }