import mixins from "vue-typed-mixins";
import MixinsComponent from "@/mixins/component";
import MixinsModalBackButton from "@/mixins/single/modal-back-button";
import core from "@/core";
import { UpdateEventType } from "@/types";
import UpdateEvent from "@/models";

/**
 * 모달 공통 처리
 */
export default mixins(MixinsComponent, MixinsModalBackButton).extend({
  data: () => ({
    modalKeyEvent: null as any,
  }),
  mounted() {
    const startDate = new Date();
    const vm = this as any;
    this.$nextTick(() => {
      this.modalKeyEvent = (e) => {
        const curDate = new Date();
        const diffTimeMillis = curDate.getTime() - startDate.getTime();
        if (diffTimeMillis < 100) {
          return;
        }
        const code = e.code;
        if (code === "Escape") {
          vm.cancel();
        } else if (code === "Enter" || code === "NumpadEnter") {
          if (vm.confirm) {
            vm.confirm();
          }
        }
      };
      window.addEventListener("keydown", this.modalKeyEvent);
    });
  },
  destroyed() {
    window.removeEventListener("keydown", this.modalKeyEvent);
  },
  methods: {
    async notFound() {
      await core.alert.show({
        title: "알림",
        body: "잘못된 요청입니다.",
        allowBackCloseEvent: false,
      });
      this.cancel();
    },
    cancel() {
      this.close(UpdateEventType.CANCEL);
    },
    close(result: UpdateEventType, item?) {
      if (result !== UpdateEventType.CANCEL) {
        this.properties.updateEvent = new UpdateEvent(result, "modal", item);
      }
      this.properties.visible = false;
    },
    confirm() {},
  },
});
