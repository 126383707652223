
import mixins from "vue-typed-mixins";
import MixinsPageForm from "@/mixins/page-form";
import NotificationViewModal from "@/modals/notification/NotificationViewModal.vue";
import { UpdateEventType } from "@/types";
import NotificationService from "@/services/notification/notification.service";
import MixinsTable from "@/mixins/single/table";
import { cloneDeep } from "lodash";
import core from "@/core";
import MixinsScroll from "@/mixins/single/scroll";
import { Menu } from "@/router";
import NotificationFilterModal from "@/modals/user/NotificationFilterModal.vue";
import UpdateEvent from "@/models";

export default mixins(MixinsPageForm, MixinsTable, MixinsScroll).extend({
  name: "Notification",
  components: { NotificationFilterModal, NotificationViewModal },
  data: () => ({
    table: {
      body: document.documentElement,
      defaultColumns: [],
      request: {
        orderColumnName: "createdAt",
        searchColumns: {
          // confirmedAtIsNull: true,
        },
      },
      firstLoading: true,
      loading: false,
    },
    modal: {
      notification: {
        visible: false,
        params: {
          item: null as any,
        },
        updateEvent: null as any as UpdateEvent,
      },
      notificationFilter: {
        visible: false,
        params: {
          filterList: null as any,
        },
        updateEvent: null as any as UpdateEvent,
      },
    },
    iconSubMenu: {
      filter: null as Menu | null,
    },
    filterList: null as any,
  }),
  mounted() {
    // 테이블 초기화
    this.initTable({
      service: NotificationService,
      serviceFunctionKey: "getTable",
      itemHeight: 56,
    });

    this.$nextTick(async () => {
      if (this.iconSubMenu.filter) {
        this.iconSubMenu.filter.badge = false;
        this.iconSubMenu.filter.badgeCount = 0;
      }

      core.loader.show("알림 처리중...");

      const notificationList = cloneDeep(this.app.data.notificationList);
      try {
        // console.log("list : ", list);
        for (let i = 0; i < notificationList.length; i++) {
          const item = notificationList[i];
          await NotificationService.update(item.id, "");
        }
      } catch (e) {
        console.log(e);
      } finally {
        core.loader.hide();
      }

      // 테이블 정보 가져오기
      this.loadTable();
    });
  },
  watch: {
    "iconSubMenu.filter.event"(event) {
      if (this.iconSubMenu.filter) {
        this.iconSubMenu.filter.event = false;
      }

      if (event) {
        // console.log("event");
        const modal = this.modal.notificationFilter;
        modal.params.filterList = this.filterList;
        modal.visible = true;
      }
    },
    "scroll.scrollTop"(scrollTop) {
      this.scrollTop = scrollTop;
    },
    "properties.updateEvent"() {
      this.clearTable();
      this.loadTable();
    },
    "app.data.notificationList"(list) {
      // console.log("change notification");
    },
    "modal.notificationFilter.updateEvent"() {
      const modal = this.modal.notificationFilter;
      const event = this.getComponentUpdateEvent(modal);
      if (event) {
        if (event.result === UpdateEventType.CONFIRM) {
          // console.log("event : ", event);
          this.filterList = event.item;
        } else {
          console.log("unknown event result : ", event);
        }
      }
    },
    filterList(list) {
      if (this.iconSubMenu.filter) {
        if (list == null) {
          this.iconSubMenu.filter.badge = false;
          this.iconSubMenu.filter.badgeCount = 0;
          this.clearTable();
          this.loadTable({});
        } else {
          this.iconSubMenu.filter.badge = true;
          this.iconSubMenu.filter.badgeCount = list.length;
          this.clearTable();
          let entityTypeIsNull = false;
          let entityTypeList = "";
          list.forEach((item) => {
            if (entityTypeList.length > 0) entityTypeList += ",";
            if (item.id === "ENTITY_TYPE_IS_NULL") {
              entityTypeIsNull = true;
            } else {
              entityTypeList += item.id;
            }
          });
          const searchColumns = {
            entityTypeList: entityTypeList,
          } as any;
          if (entityTypeIsNull) {
            searchColumns.entityTypeIsNull = true;
          }
          this.loadTable(searchColumns);
        }
      }
    },
  },
  methods: {
    showViewModal(item) {
      const modal = this.modal.notification;
      modal.params.item = item;
      modal.visible = true;
      //console.log("item : ", item);
      // this.$router.push(`/support/${id}`);
    },
  },
});
