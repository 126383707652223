
import mixins from "vue-typed-mixins";
import MixinsModal from "@/mixins/modal";
import UpdateEvent from "@/models";
import { UpdateEventType } from "@/types";
import { Role } from "@/models/user/user.model";
import UserService from "@/services/user/user.service";

export interface RoleItem {
  id: string;
  name: string;
  checked: boolean;
}

export default mixins(MixinsModal).extend({
  name: "RoleEditModal",
  props: {
    properties: {
      type: Object,
      default: () => {
        return {
          visible: false,
          params: {
            roles: null as null | Role[],
          },
          updateEvent: null as UpdateEvent | null,
        };
      },
    },
  },
  data: () => ({
    roleList: [] as RoleItem[],
  }),
  mounted() {
    this.$nextTick(() => {
      const roles = this.properties.params.roles;
      if (roles) {
        [Role.ADMIN, Role.MANAGER, Role.USER].forEach((role) => {
          this.roleList.push({
            id: role,
            name: UserService.roleToText(role),
            checked: roles.indexOf(role) > -1,
          });
        });
      } else {
        console.log("invalid param : ", roles);
        this.close(UpdateEventType.CANCEL);
      }
    });
  },
  methods: {
    select(item) {
      // console.log("item : ", item);
      this.close(UpdateEventType.CONFIRM, item);
    },
  },
});
