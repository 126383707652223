// Polyfills
import "core-js/stable";
import "regenerator-runtime/runtime";
import "./registerServiceWorker";

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VuePageStack from "@/plugins/vue-page-stack";

import { extend, localize } from "vee-validate";
import { email, max, min, required } from "vee-validate/dist/rules";
import core from "@/core";
import TopToolbar from "@/components/core/TopToolbar.vue";
import SelectSearchModal from "@/modals/core/SelectSearchModal.vue";
import SelectCameraModal from "@/modals/core/SelectCameraModal.vue";

import Constant from "@/store/constant";
import ComponentTemplate from "@/components/core/ComponentTemplate.vue";

import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";

import "viewerjs/dist/viewer.css";
import Viewer from "v-viewer";
import { MobileSystemProperties } from "@/types";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

require("@/assets/js/kakao.js");
(window as any).Kakao.init(Constant.kakao.javascriptKey);

try {
  // https://firebase.google.com/docs/web/setup#available-libraries
  // Your web app's Firebase configuration
  // For Firebase JS SDK v7.20.0 and later, measurementId is optional
  const firebaseConfig = {
    apiKey: "AIzaSyBKDM4N63mWTuPEPuqeKtiSkgXwB2xPofQ",
    authDomain: "net-khwan.firebaseapp.com",
    projectId: "net-khwan",
    storageBucket: "net-khwan.appspot.com",
    messagingSenderId: "209083603135",
    appId: "1:209083603135:web:47b0d83d552a88c07a0e0a",
    measurementId: "G-9GEBG42CNB",
  };

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);
  const messaging = getMessaging(app);

  // 알림 수신을 위한 사용자 권한 요청
  Notification.requestPermission().then((permission) => {
    console.log("permission ", permission);
    if (permission !== "granted") {
      core.alert.show({
        title: "확인",
        body: "알림을 허용해주세요!",
      });
    }
  });

  getToken(messaging, {
    vapidKey:
      "BAlygxcOht6GNn0YL4rB_X-LVHh8EcfCVSl0pT1de1mjo0fJBreVWs_FuJGnwBiMl2wd0XrWE8Ta1tWgVWW8Am4",
  }).then((token) => {
    console.log("token : ", token);
    if (token != null) {
      const systemProperties = { deviceToken: token } as MobileSystemProperties;

      (window as any).updateMobileSystemProperties(JSON.stringify(systemProperties));
    }
  });

  // Handle received push notification at foreground
  // onMessage(messaging, (payload) => {
  //   const data = payload.data;
  //   if (data != null) {
  //     //console.log("data : ", data);
  //
  //     const notificationTitle = data.title;
  //     const notificationOptions = {
  //       body: data.body,
  //       icon: "/img/icons/android-icon-192x192.png",
  //     };
  //
  //     const route = router.currentRoute;
  //     //console.log("dataPath : ", data.path, ", routePath : ", route.path);
  //     if (data.path !== route.path) {
  //       new Notification(notificationTitle, notificationOptions);
  //     }
  //   }
  // });
} catch (e) {
  console.log(e);
}

Vue.config.productionTip = false;

const options = {
  timeout: false,
  maxToasts: 1,
};

Vue.use(Toast, options);

Vue.use(Viewer);

// 공통 유틸 초기화
core.init(store, router, Vue.$toast);

Vue.component("top-toolbar", TopToolbar);

// 아이템 검색 및 선택 모달
Vue.component("select-search-modal", SelectSearchModal);

// 카메라 선택 모달
Vue.component("select-camera-modal", SelectCameraModal);

Vue.component("scc-component-template", ComponentTemplate);

if (!("toJSON" in Error.prototype))
  Object.defineProperty(Error.prototype, "toJSON", {
    value: function () {
      const alt = {};

      Object.getOwnPropertyNames(this).forEach(function (key) {
        try {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          alt[key] = this[key];
        } catch (e) {
          alt[key] = e;
        }
      }, this);

      return alt;
    },
    configurable: true,
    writable: true,
  });

Vue.config.errorHandler = (error, vm, info) => {
  console.log("error : ", error);
  core.http.sendErrorLog(error);
};

if (process.env.NODE_ENV === "production") {
  if ((window as any).console == undefined) (window as any).console = {};
  (window as any).console.log = function () {};
  (window as any).console.warn = function () {};
  (window as any).console.error = function () {};
}

Vue.prototype.$eventBus = new Vue();

// page stack(이전 페이지 저장 처리)
Vue.use(VuePageStack, {
  router,
  routerBeforeTask: store.state.app.routerBeforeTask,
  name: "vue-page-stack",
  keyName: "stackKey",
});

// validate
extend("required", required);
extend("min", min);
extend("max", max);
extend("email", email);
extend("phone", {
  validate(value) {
    return core.utils.validate.phone(value);
  },
  message: "휴대전화 형식이 올바르지 않습니다",
});
extend("password", {
  params: ["target"],
  validate(value, params) {
    const target = (params as any).target;
    // console.log('value : ', value, ', target : ', target);
    return value === target;
  },
  message: "비밀번호 확인이 일치하지 않습니다",
});

localize("ko", {
  messages: Constant.validate.language.korea.messages,
  names: {
    user_username: "아이디 ",
    user_password: "비밀번호 ",
    user_confirmPassword: "비밀번호 확인  ",
    user_email: "이메일 ",
    user_name: "이름 ",
    user_phone: "휴대전화 ",
    user_emailCertificationNumber: "인증번호 ",
    user_phoneCertificationNumber: "인증번호 ",
    user_currentPassword: "현재 비밀번호 ",
    media_job_title: "제목",
    media_job_url: "URL",
    media_job_cookie: "Cookie",
    media_job_origin: "Origin",
    media_job_referer: "Referer",
    media_job_description: "설명",
    memo: "메모 ",
  },
});

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
