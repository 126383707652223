
import mixins from "vue-typed-mixins";
import MixinsPageForm from "@/mixins/page-form";
import MixinsTable from "@/mixins/single/table";
import MixinsScroll from "@/mixins/single/scroll";
import { Menu } from "@/router";
import { UpdateEventType } from "@/types";
import UserListItemComponent from "@/components/user/ListItemComponent.vue";
import UserMgmtService from "@/services/user/user-mgmt.service";
import core from "@/core";

export default mixins(MixinsPageForm, MixinsTable, MixinsScroll).extend({
  name: "User",
  components: { UserListItemComponent },

  data: () => ({
    table: {
      body: document.documentElement,
      defaultColumns: [],
      firstLoading: true,
      loading: false,
    },
    iconSubMenu: { search: null as Menu | null },
  }),
  mounted() {
    // 테이블 초기화
    this.initTable({
      service: UserMgmtService,
      serviceFunctionKey: "getTable",
      itemHeight: 100,
    });

    // 페이지 로딩 후 호출
    this.$nextTick(async () => {
      // 테이블 정보 가져오기
      this.loadTable();
    });
  },
  watch: {
    "scroll.scrollTop"(scrollTop) {
      this.scrollTop = scrollTop;
    },
    "iconSubMenu.search.event"(event) {
      const item = this.iconSubMenu.search;
      item.event = false;
      if (event) {
        this.$router.push({
          path: "/mgmt/user/search",
          query: { stackKey: core.utils.textGenerator(8) },
        });
      }
    },
  },
  activated() {
    const event = this.getPageUpdateEvent();
    if (event != null) {
      if (event.result === UpdateEventType.UPDATE) {
        if (event.path.indexOf("search") > -1) {
          this.updateTableItem("id", event.item);
        } else {
          this.addTableItem("id", event.item);
        }
      } else if (event.result === UpdateEventType.DELETE) {
        this.deleteTableItem("id", event.item);
      } else {
        console.log("unknown event : ", event);
      }
    }
  },
});
