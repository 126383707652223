
import mixins from "vue-typed-mixins";
import MixinsPageForm from "@/mixins/page-form";
import SelectShareSocialModal from "@/modals/user/SelectShareSocialModal.vue";
import MediaJobService from "@/services/media/media-job.service";
import { TableRequest, UpdateEventType } from "@/types";
import UpdateEvent from "@/models";
import MediaJobModal from "@/modals/media/MediaJobModal.vue";
import {
  MediaJobModel,
  MediaStatusType,
  PlayVideoModel,
  VideoType,
} from "@/models/media/media.model";
import Hls from "hls.js";
import MixinsTable from "@/mixins/single/table";
import MixinsScroll, { ScrollPosition } from "@/mixins/single/scroll";
import MediaJobCardComponent from "@/components/media/job/CardComponent.vue";
import Constant from "@/store/constant";
import MixinsStorage from "@/mixins/single/storage";

export default mixins(MixinsPageForm, MixinsTable, MixinsScroll, MixinsStorage).extend({
  name: "MgmtMedia",
  components: { MediaJobCardComponent, MediaJobModal, SelectShareSocialModal },
  data: () => ({
    storageItems: {
      type: "media",
    },
    table: {
      body: document.documentElement,
      defaultColumns: [],
      firstLoading: true,
      loading: false,
    },
    mediaJobList: [] as MediaJobModel[],
    modal: {
      addEdit: {
        visible: false,
        params: {
          type: "add",
          title: "",
          url: "",
          cookie: "",
          origin: "",
          referer: "",
        },
        updateEvent: null as UpdateEvent | null,
      },
    },
    video: {
      play: false,
      url: "",
      hlsPlayer: null as Hls | null,
      job: null as MediaJobModel | null,
    } as PlayVideoModel,
    videoMaxHeight: 100,
    running: true,
  }),
  computed: {
    MediaStatusType() {
      return MediaStatusType;
    },
    isManagerRoleHigher() {
      return this.$store.getters["auth/isManagerRoleHigher"];
    },
    isAdminRole() {
      return this.$store.getters["auth/isAdminRole"];
    },
  },
  mounted() {
    this.$nextTick(async () => {
      await this.storageWait();
      const storageItems = this.getStorageItems();
      if (storageItems.setting == null) {
        storageItems.setting = {
          volume: 1,
          muted: false,
        };
        this.saveStorageItems();
      }

      // 테이블 초기화
      this.initTable({
        service: MediaJobService,
        serviceFunctionKey: "getTable",
        itemHeight: 100,
      });

      this.resizeWindow();
      this.startTask(0);
      // 테이블 정보 가져오기
      this.loadTable({
        processingYn: "Y",
      });
    });
  },
  watch: {
    "scroll.scrollTop"(scrollTop) {
      this.scrollTop = scrollTop;
    },
    "modal.addEdit.updateEvent"() {
      const event = this.getComponentUpdateEvent(this.modal.addEdit);
      if (event) {
        if (event.result == UpdateEventType.RELOAD) {
          this.loadLiveJobList();
        }
      }
    },
    "app.size"() {
      this.resizeWindow();
    },
    async "video.job"(job, preJob) {
      if (job != null) {
        if (preJob != null) {
          await this.pauseVideo();
          await this.closeElement("video");
        }
        this.playVideo(job);
      } else {
        this.pauseVideo();
      }
    },
  },
  destroyed() {
    if (this.running) {
      console.log("clear interval");
      this.running = false;
    }
  },
  methods: {
    resizeWindow() {
      const videoMaxHeight = this.app.size.height - 200;
      const ratio = this.app.size.width / 16;
      const height = ratio * 9;

      let changeHeight = videoMaxHeight > height ? height : videoMaxHeight;

      this.videoMaxHeight = changeHeight;
      // if (this.$refs.video != null) {
      //   (this.$refs.video as any).parentNode.style.height = changeHeight + "px";
      //   (this.$refs.video as any).parentNode.style.maxHeight = changeHeight + "px";
      // }
    },
    async playVideo(item: MediaJobModel) {
      // this.pauseVideo();
      if (this.isNotBlank(item.videoUri)) {
        const video = this.video;
        if (video.play) {
          console.log("already play");
          await this.pauseVideo();
        }

        const storageItems = this.getStorageItems();

        video.play = true;
        video.url = Constant.videoServerUrl + item.videoUri;

        const videoEl = await this.findElement("video");
        if (videoEl == null) {
          console.log("not found videoElement : ", videoEl);
          return;
        }
        videoEl.setAttribute("src", "");
        if (storageItems.setting != null) {
          videoEl.muted = storageItems.setting.muted;
          videoEl.volume = storageItems.setting.volume;
        }

        if (item.videoType === VideoType.HLS) {
          // console.log("videoEl : ", videoEl);
          if (Hls.isSupported()) {
            const hlsPlayer = new Hls();
            this.video.hlsPlayer = hlsPlayer;

            hlsPlayer.loadSource(video.url);
            hlsPlayer.attachMedia(videoEl);
            // hlsPlayer.on(Hls.Events.MANIFEST_PARSED, () => {
            //   console.log("parsed");
            // });

            let errorCount = 0;
            hlsPlayer.on(Hls.Events.ERROR, (event, data) => {
              if (event === "hlsError") {
                if (data.type === "networkError") {
                  errorCount++;
                  console.log("errorCount : ", errorCount);
                  if (errorCount > 5) {
                    this.closeVideo();
                  } else {
                    videoEl.play();
                  }
                }
              }
            });
            hlsPlayer.on(Hls.Events.BUFFER_APPENDED, () => {
              // console.log("BUFFER_APPENDED");
              errorCount = 0;
            });
            videoEl.addEventListener("loadeddata", () => {
              videoEl.play();
            });
          } else if (videoEl.canPlayType("application/vnd.apple.mpegurl")) {
            videoEl.setAttribute("type", "application/x-mpegURL");
            videoEl.setAttribute("src", video.url);
            videoEl.play();
            videoEl.onerror = () => {
              const error = videoEl.error;
              console.log("error : ", error);
              this.closeVideo();
            };
          }
          this.setScrollPosition(ScrollPosition.TOP);
        } else if (item.videoType === VideoType.MP4) {
          videoEl.setAttribute("type", "video/mp4");
          videoEl.setAttribute("src", video.url);
          videoEl.addEventListener("loadeddata", () => {
            console.log("loaded");
            videoEl.play();
          });
          videoEl.onerror = () => {
            const error = videoEl.error;
            console.log("error : ", error);
            this.closeVideo();
          };
          this.setScrollPosition(ScrollPosition.TOP);
        } else {
          console.log("invalid videoType : ", item.videoType);
        }
        videoEl.addEventListener("volumechange", async () => {
          if (storageItems.setting == null) storageItems.setting = {};
          storageItems.setting.volume = videoEl.volume;
          storageItems.setting.muted = videoEl.muted;
          this.saveStorageItems();
          // console.log("change volume : ", videoEl.volume, ", muted : ", videoEl.muted);
        });
      }
    },
    async closeVideo() {
      await this.pauseVideo();
      this.video.job = null;
      await this.closeElement("video");
    },
    async pauseVideo() {
      const video = this.video;
      if (video.play) {
        const videoEl = await this.findElement("video");
        if (videoEl != null) {
          videoEl.pause();
        }
        if (video.hlsPlayer != null) {
          video.hlsPlayer.destroy();
          video.hlsPlayer = null;
        }
        video.play = false;
      }
    },
    startTask(timeout) {
      // console.log("startTask. " + timeout);
      if (this.running) {
        setTimeout(async () => {
          if (!this.running) {
            return;
          }

          try {
            // console.log("loadLiveJobList");
            await this.loadLiveJobList();
          } catch (e) {
            console.log(e);
          }
          this.startTask(5000);
        }, timeout);
      }
    },
    createJob() {
      const modal = this.modal.addEdit;
      modal.params.type = "add";
      modal.params.title = "";
      modal.params.url = "";
      modal.params.cookie = "";
      modal.params.origin = "";
      modal.params.referer = "";
      modal.visible = true;
    },
    createJobFromJob(job: MediaJobModel) {
      const modal = this.modal.addEdit;
      modal.params.type = "add";
      modal.params.title = job.title;
      modal.params.url = job.url;
      modal.params.cookie = job.cookie;
      modal.params.origin = job.origin == null ? "" : job.origin;
      modal.params.referer = job.referer == null ? "" : job.referer;
      modal.visible = true;
    },
    async loadLiveJobList() {
      const params = {
        draw: 0,
        start: 0,
        length: 100,
        orderColumnName: "createdAt",
        order: "desc",
        searchColumns: {
          processingYn: "N",
        },
      } as TableRequest;
      const response = await MediaJobService.getTable(params, { ignoreAlertModal: true });
      const jobMap = {};
      this.mediaJobList.forEach((job) => {
        jobMap[job.id] = job;
      });

      const newJobList = [] as MediaJobModel[];
      response.data.forEach((data) => {
        newJobList.push(data);
        delete jobMap[data.id];
      });
      this.mediaJobList = newJobList;

      const length = Object.keys(jobMap).length;
      if (length > 0) {
        this.reloadTable();
      }
    },
    async cancel(item: MediaJobModel) {
      try {
        const job = await MediaJobService.updateStatus(item.id, MediaStatusType.CANCEL);
        console.log("job : ", job);
      } catch (e) {
        console.log(e);
      }
    },
  },
});
