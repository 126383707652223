import core from "@/core";
import { TableRequest, TableResponse } from "@/types";
import AbstractCrudService from "@/services/core/abstract-crud.service";

abstract class AbstractTableService<T> extends AbstractCrudService<T> {
  getTable(
    params: TableRequest,
    config?: any,
    replaceParams?: [string]
  ): Promise<TableResponse<T>> {
    const url = this.getUrl(replaceParams);
    //console.log("this.url : ", this.url);
    return new Promise((resolve, reject) => {
      core.http
        .getJson(`${url}/table`, config, params)
        .then((data) => {
          resolve(data);
        })
        .catch((reason) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }
}

export default AbstractTableService;
