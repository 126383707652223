
import mixins from "vue-typed-mixins";
import MixinsCommon from "@/mixins/single/common";
import store from "@/store";
import AppBottomBarProps from "@/components/core/AppBottomBarProps.vue";
import { MenuType } from "@/router";

export default mixins(AppBottomBarProps, MixinsCommon).extend({
  name: "AppBottomBar",
  data: () => ({
    menuList: [],
    app: store.state.app,
    auth: store.state.auth,
    menuItems: {
      selected: null as any,
      list: [] as any,
    },
  }),
  computed: {
    MenuType() {
      return MenuType;
    },
  },
  methods: {
    goBack() {
      this.$router.back();
    },
  },
});
