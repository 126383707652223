import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import NotSupportBrowser from "@/views/errors/NotSupportBrowser.vue";
import NotFound from "@/views/errors/NotFound.vue";
import Home from "@/views/Home.vue";
import Logout from "@/views/Logout.vue";
import core from "@/core";
import store from "@/store";
import Login from "@/views/Login.vue";
import Constant from "@/store/constant";
import Register from "@/views/Register.vue";
import Notification from "@/views/Notification.vue";
import Settings from "@/views/Settings.vue";
import UserMyInfoView from "@/views/user/MyInfoView.vue";
import UserMyInfoEdit from "@/views/user/MyInfoEdit.vue";
import UserMyPassword from "@/views/user/MyPassword.vue";
import UserMyWithdraw from "@/views/user/MyWithdraw.vue";
import SearchAddress from "@/views/SearchAddress.vue";
import MgmtUser from "@/views/mgmt/User.vue";
import MgmtUserView from "@/views/mgmt/UserView.vue";
import { Role } from "@/models/user/user.model";
import MgmtUserSearch from "@/views/mgmt/UserSearch.vue";
import MgmtMedia from "@/views/mgmt/Media.vue";
import FindUsername from "@/views/FindUsername.vue";
import FindPassword from "@/views/FindPassword.vue";

Vue.use(VueRouter);

const appName = Constant.appName;

export enum MenuType {
  HOME = "home",
  AUTO_NAV_BOTTOMBAR = "auto_nav_battombar",
  AUTO_NAV_BACK = "auto_nav_back",
  NAVIGATION = "navigation",
  BACK = "back",
  TITLE = "title",
  NONE = "none",
  BOTTOMBAR = "bottombar",
}

export interface SubMenu {
  text: SubTextMenu | null | undefined;
  icon: SubIconMenu | null | undefined;
}

export interface SubIconMenu {
  visible: boolean;
  list: Menu[] | null;
}

export interface SubTextMenu {
  visible: boolean;
  width: number;
  list: Menu[] | null;
}

export interface Menu {
  icon: string;
  text: string;
  id: string;
  visible: boolean;
  event: boolean;
  badge: false;
  badgeCount: number;
}

export interface RouteMeta {
  title: string;
  roles: Role[] | null;
  menuType: MenuType;
  pageStack: boolean;
  subMenu: SubMenu | null;
  scrollable: boolean;
}

const routes: Array<RouteConfig> = [
  {
    path: "/",
    redirect: "/home",
    meta: {
      title: "",
      roles: null,
      menuType: MenuType.NONE,
      pageStack: false,
      subMenu: null,
      scrollable: true,
    } as RouteMeta,
  },
  {
    path: "/home",
    name: "home",
    component: Home,
    meta: {
      title: "홈",
      roles: [Role.ADMIN, Role.MANAGER, Role.USER],
      menuType: MenuType.HOME,
      pageStack: false,
      subMenu: null,
      scrollable: true,
    } as RouteMeta,
  },
  {
    path: "/register",
    name: "register",
    component: Register,
    meta: {
      title: "회원가입",
      roles: null,
      menuType: MenuType.BACK,
      pageStack: false,
      subMenu: null,
      scrollable: true,
    } as RouteMeta,
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {
      title: "로그인",
      roles: null,
      menuType: MenuType.NONE,
      pageStack: false,
      subMenu: null,
      scrollable: true,
    } as RouteMeta,
  },
  {
    path: "/find-username",
    name: "findUsername",
    component: FindUsername,
    meta: {
      title: "사용자 아이디 찾기",
      roles: null,
      menuType: MenuType.BACK,
      pageStack: false,
      subMenu: null,
      scrollable: true,
    } as RouteMeta,
  },
  {
    path: "/find-password",
    name: "findPassword",
    component: FindPassword,
    meta: {
      title: "사용자 비밀번호 찾기",
      roles: null,
      menuType: MenuType.BACK,
      pageStack: false,
      subMenu: null,
      scrollable: true,
    } as RouteMeta,
  },
  {
    path: "/logout",
    name: "logout",
    component: Logout,
    meta: {
      title: "로그아웃",
      roles: [Role.ADMIN, Role.MANAGER, Role.USER],
      menuType: MenuType.NONE,
      pageStack: false,
      subMenu: null,
      scrollable: true,
    } as RouteMeta,
  },
  {
    path: "/error/not-support-browser",
    name: "notSupportBrowser",
    component: NotSupportBrowser,
    meta: {
      title: "지원하지 않는 브라우저 입니다",
      roles: null,
      menuType: MenuType.NONE,
      pageStack: false,
      subMenu: null,
      scrollable: true,
    } as RouteMeta,
  },
  {
    path: "*",
    name: "notFound",
    component: NotFound,
    meta: {
      title: "페이지를 찾을 수 없습니다",
      roles: null,
      menuType: MenuType.BACK,
      pageStack: false,
      subMenu: null,
      scrollable: true,
    } as RouteMeta,
  },
  {
    path: "/search-address",
    name: "searchAddress",
    component: SearchAddress,
    meta: {
      title: "주소검색",
      roles: [Role.ADMIN, Role.MANAGER, Role.USER],
      menuType: MenuType.NONE,
      pageStack: false,
      subMenu: null,
      scrollable: true,
    } as RouteMeta,
  },
  {
    path: "/notification",
    name: "notification",
    component: Notification,
    meta: {
      title: "알림",
      roles: [Role.ADMIN, Role.MANAGER, Role.USER],
      menuType: MenuType.NAVIGATION,
      pageStack: true,
      subMenu: {
        icon: {
          visible: true,
          list: [
            {
              icon: "mdi-filter-variant",
              text: "필터",
              id: "filter",
              visible: true,
              event: false,
              badge: false,
              badgeCount: 0,
            },
          ],
        },
        text: null,
      },
      scrollable: true,
    } as RouteMeta,
  },
  {
    path: "/settings",
    name: "settings",
    component: Settings,
    meta: {
      title: "설정",
      roles: [Role.ADMIN, Role.MANAGER, Role.USER],
      menuType: MenuType.NAVIGATION,
      pageStack: false,
      subMenu: null,
      scrollable: true,
    } as RouteMeta,
  },
  {
    path: "/user/me/info",
    name: "userMyInfoView",
    component: UserMyInfoView,
    meta: {
      title: "회원정보",
      roles: [Role.ADMIN, Role.MANAGER, Role.USER],
      menuType: MenuType.AUTO_NAV_BACK,
      pageStack: false,
      scrollable: true,
      subMenu: {
        icon: {
          visible: true,
          list: [
            {
              icon: "",
              text: "수정",
              id: "edit",
              visible: true,
              event: false,
            },
          ],
        },
        text: null,
      },
    } as RouteMeta,
  },
  {
    path: "/user/me/info/edit",
    name: "userMyInfoEdit",
    component: UserMyInfoEdit,
    meta: {
      title: "회원정보",
      roles: [Role.ADMIN, Role.MANAGER, Role.USER],
      menuType: MenuType.AUTO_NAV_BACK,
      pageStack: false,
      subMenu: null,
      scrollable: true,
    } as RouteMeta,
  },
  {
    path: "/user/me/password",
    name: "userMyPassword",
    component: UserMyPassword,
    meta: {
      title: "비밀번호 변경",
      roles: [Role.ADMIN, Role.MANAGER, Role.USER],
      menuType: MenuType.AUTO_NAV_BACK,
      pageStack: false,
      subMenu: null,
      scrollable: true,
    } as RouteMeta,
  },
  {
    path: "/user/me/withdraw",
    name: "userMyWithdraw",
    component: UserMyWithdraw,
    meta: {
      title: "회원탈퇴",
      roles: [Role.ADMIN, Role.MANAGER, Role.USER],
      menuType: MenuType.AUTO_NAV_BACK,
      pageStack: false,
      subMenu: null,
      scrollable: true,
    } as RouteMeta,
  },
  {
    path: "/mgmt/media",
    name: "mgmtMedia",
    component: MgmtMedia,
    meta: {
      title: "미디어 작업",
      roles: [Role.ADMIN],
      menuType: MenuType.NAVIGATION,
      pageStack: false,
      subMenu: null,
      scrollable: true,
    } as RouteMeta,
  },
  {
    path: "/mgmt/user",
    name: "mgmtUser",
    component: MgmtUser,
    meta: {
      title: "사용자 관리",
      roles: [Role.ADMIN, Role.MANAGER],
      menuType: MenuType.AUTO_NAV_BACK,
      pageStack: true,
      subMenu: {
        icon: {
          visible: true,
          list: [
            {
              icon: "mdi-magnify",
              text: "검색",
              id: "search",
              visible: true,
              event: false,
            },
          ],
        },
        text: null,
      },
      scrollable: true,
    } as RouteMeta,
  },
  {
    path: "/mgmt/user/:id(\\d+)",
    name: "mgmtUserView",
    component: MgmtUserView,
    meta: {
      title: "사용자",
      roles: [Role.ADMIN, Role.MANAGER],
      menuType: MenuType.AUTO_NAV_BACK,
      pageStack: false,
      subMenu: null,
      scrollable: true,
    } as RouteMeta,
  },
  {
    path: "/mgmt/user/search",
    name: "mgmtUserSearch",
    component: MgmtUserSearch,
    meta: {
      title: "사용자 검색",
      roles: [Role.ADMIN, Role.MANAGER],
      menuType: MenuType.NONE,
      pageStack: true,
      subMenu: null,
      scrollable: true,
    } as RouteMeta,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

const routeMap = new Map();
routes.forEach((data) => {
  if (data.children != null) {
    const path = data.path;
    data.children.forEach((data) => {
      routeMap.set(path + data.path, data);
    });
  } else {
    routeMap.set(data.path, data);
  }
});

async function routerResult(next, path: string): Promise<RouteMeta | null> {
  next({ path: path });
  const route = routeMap.get(path);
  // console.log("routerResult path : ", path);
  if (route) {
    return route.meta;
  }
  return routeMap.get("*");
}

async function beforeEach(to, from, next): Promise<RouteMeta | null> {
  const user = await store.getters["auth/user"]();
  if (user) {
    // 로그인 중인 사용자
    if (to.meta.roles != null && to.meta.roles.length > 0) {
      let accessible = false;
      user.roles.some((role) => {
        if (to.meta.roles.indexOf(role) > -1) {
          accessible = true;
          return true;
        }
      });

      if (accessible) {
        next();
      } else {
        return await routerResult(next, "/not-found");
      }
    } else {
      // 비로그인 사용자 접근 가능 페이지일 경우 이동
      if (to.path.toLowerCase() === "/login") {
        if (core.utils.validate.isBlank(to.query.v)) {
          // 로그인중인 사용자가 로그인 페이지 접근시 이동
          return await routerResult(next, "/home");
        } else {
          // 로그인중인 사용자가 자동인증 정보파라미터를 포함해서 들어왔을 경우 로그인 페이지로 이동
          next();
        }
      } else {
        next();
      }
      // console.log("로그인 사용자 next, 페이지 권한 필요없음");
    }
  } else {
    if (to.meta.roles != null && to.meta.roles.length > 0) {
      // 로그인 사용자 접근 가능 페이지일 경우 메인 페이지로 이동
      return await routerResult(next, "/login");
    } else {
      next();
      // console.log("비로그인 사용자 next, 페이지 권한 필요없음");
    }
  }

  return to.meta;
}

let first = true;
router.beforeEach(async (to, from, next) => {
  // console.log("from   : ", from);
  // console.log("to     : ", to);
  // console.log("from " + from.path + " -> to : " + to.path);
  if (first) {
    first = false;
    core.preloader.show();
  }

  if (store.state.topToolbar.changedStyle) {
    await store.dispatch("topToolbar/changeOrgStyle");
  }

  if (core.toast.isShow()) {
    core.toast.hide();
  }

  if (
    (from.path === "/home" && to.path === "/login") ||
    (from.path === "/login" && to.path === "/login")
  ) {
    window.history.back();
    return;
  }

  const meta = await beforeEach(to, from, next);
  if (meta != null) {
    if (meta.title != null) {
      if (meta.title.length > 0) {
        document.title = `${appName} - ${meta.title}`;
      } else {
        document.title = appName;
      }
    }

    if (meta.menuType !== MenuType.NONE) {
      await store.dispatch("topToolbar/changeTitle", { title: meta.title, ignoreCheck: true });
    }

    if (!meta.scrollable) {
      document.documentElement.classList.add("overflow-y-hidden");
    } else {
      document.documentElement.classList.remove("overflow-y-hidden");
    }
  }

  if (core.preloader.isShow()) {
    core.preloader.hide();
  }
});

export default router;
