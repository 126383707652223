
import mixins from "vue-typed-mixins";
import MixinsCommon from "@/mixins/single/common";
import { MediaJobModel, MediaStatusType, PlayVideoModel } from "@/models/media/media.model";
import MediaJobService from "@/services/media/media-job.service";
import core from "@/core";

export default mixins(MixinsCommon).extend({
  name: "MediaJobCardComponent",

  props: {
    item: {
      type: Object as () => MediaJobModel,
      default: () => {
        return null as MediaJobModel | null;
      },
    },
    video: {
      type: Object as () => PlayVideoModel,
    },
    createJobFromJob: {
      type: Function,
    },
  },
  methods: {
    videoRecordingTimeFormat(item: MediaJobModel) {
      if (item.recordingStartAt != null) {
        // item.recordingEndAt = "2023-02-11 23:00:00";
        let recordingEndAt = item.recordingEndAt;
        if (recordingEndAt == null)
          recordingEndAt = core.date.instance().format("YYYY-MM-DD HH:mm:ss");
        return this.elapsedTime(item.recordingStartAt, recordingEndAt);
      }
    },
    elapsedTime(startAt: string, endAt: string): string {
      const curTime = core.date.instance(endAt);
      let diffTime = curTime.diff(startAt, "minute");
      let elapsedTime = "";
      if (diffTime > 60) {
        diffTime = curTime.diff(startAt, "hour");
        if (diffTime <= 0) diffTime = 1;
        elapsedTime = diffTime + "시간";
      }
      elapsedTime += (elapsedTime.length > 0 ? " " : "") + (diffTime % 60) + "분";
      return elapsedTime;
    },
    isPlayVideo(item: MediaJobModel): boolean {
      return this.isNotBlank(item.videoUri);
    },
    pauseVideo() {
      this.video.job = null;
    },
    playVideo(item: MediaJobModel) {
      this.video.job = item;
    },
    async cancel(item: MediaJobModel) {
      try {
        const job = await MediaJobService.updateStatus(item.id, MediaStatusType.CANCEL);
        // console.log("job : ", job);
        core.utils.copy(job, item);
      } catch (e) {
        console.log(e);
      }
    },
    isJobProcessing(item: MediaJobModel) {
      return (
        item.status === MediaStatusType.WAIT ||
        item.status === MediaStatusType.REQUEST ||
        item.status === MediaStatusType.PROCESSING ||
        item.status === MediaStatusType.CANCELING
      );
    },
    hasLoadingIcon(item: MediaJobModel) {
      return (
        item.changeStatus == MediaStatusType.CANCEL ||
        item.status === MediaStatusType.WAIT ||
        item.status === MediaStatusType.REQUEST ||
        item.status === MediaStatusType.PROCESSING ||
        item.status === MediaStatusType.CANCELING
      );
    },
    statusToColor(item: MediaJobModel) {
      if (item.changeStatus == MediaStatusType.CANCEL) {
        return "green";
      } else if (item.status == MediaStatusType.WAIT) {
        return "grey";
      } else if (item.status == MediaStatusType.REQUEST) {
        return "orange";
      } else if (item.status == MediaStatusType.PROCESSING) {
        return "green";
      } else if (item.status == MediaStatusType.FAILED) {
        return "red";
      } else if (item.status == MediaStatusType.CANCEL) {
        return "black";
      } else if (item.status == MediaStatusType.CANCELING) {
        return "orange";
      } else if (item.status == MediaStatusType.COMPLETE) {
        return "blue";
      } else {
        return "";
      }
    },
    statusToColorClass(item: MediaJobModel) {
      const color = this.statusToColor(item);
      if (color.length > 0) {
        return color + "--text";
      }
    },
    statusToText(item: MediaJobModel) {
      if (item.changeStatus == MediaStatusType.CANCEL) {
        return "취소 요청중";
      } else if (item.status == MediaStatusType.WAIT) {
        return "대기중";
      } else if (item.status == MediaStatusType.REQUEST) {
        return "녹화 요청중";
      } else if (item.status == MediaStatusType.PROCESSING) {
        return "녹화중";
      } else if (item.status == MediaStatusType.FAILED) {
        return "실패";
      } else if (item.status == MediaStatusType.CANCEL) {
        if (item.recordingEndAt) return "완료";
        return "취소됨";
      } else if (item.status == MediaStatusType.CANCELING) {
        return "작업 취소중";
      } else if (item.status == MediaStatusType.COMPLETE) {
        return "완료";
      } else {
        return item.status;
      }
    },
  },
});
