
import mixins from "vue-typed-mixins";
import MixinsPageForm from "@/mixins/page-form";
import { UserModel } from "@/models/user/user.model";
import { Menu } from "@/router";

export default mixins(MixinsPageForm).extend({
  name: "UserMyInfoView",
  data: () => ({
    type: "view",
    view: {
      username: "",
      email: "",
      name: "",
    },
    user: null as UserModel | null,
    iconSubMenu: { edit: null as Menu | null },
  }),
  mounted() {
    // 페이지 로딩 후 호출
    this.$nextTick(async () => {
      const user: UserModel = (this.user = await this.$store.getters["auth/user"]());
      //console.log("user : ", user);
      this.view.username = user.username;
      this.view.email = user.email;
      this.view.name = user.name;
    });
  },
  watch: {
    "iconSubMenu.edit.event"(event) {
      const item = this.iconSubMenu.edit;
      item.event = false;
      if (event) {
        this.$router.push("/user/me/info/edit");
      }
    },
  },
});
