
import mixins from "vue-typed-mixins";
import MixinsModalForm from "@/mixins/modal-form";
import { localize } from "vee-validate";
import Constant from "@/store/constant";
import { UpdateEventType } from "@/types";
import UpdateEvent from "@/models";
import MediaJobService from "@/services/media/media-job.service";

localize("ko", {
  messages: Constant.validate.language.korea.messages,
  names: {
    field: "",
  },
});

export default mixins(MixinsModalForm).extend({
  name: "MediaJobModal",
  props: {
    properties: {
      type: Object,
      default: () => {
        return {
          visible: false,
          params: {
            type: "add",
            url: "",
            cookie: "",
            origin: "",
            referer: "",
            description: "",
          },
          // 업데이트 이벤트 처리용 변수
          updateEvent: null as UpdateEvent | null,
        };
      },
    },
  },
  data: () => ({
    ready: false,
    form: {
      title: "",
      url: "",
      cookie: "",
      origin: "",
      referer: "",
      description: "",
      pwd: "",
    },
    type: "",
    visible: {
      cancelBtn: false,
    },
    formConvertField: {
      title: "media_job_title",
      url: "media_job_url",
      cookie: "media_job_cookie",
      origin: "media_job_origin",
      referer: "media_job_referer",
      description: "media_job_description",
      pwd: "media_job_pwd",
    },
  }),
  async created() {
    // 생성 후 호출
    const properties = this.properties;
    this.type = properties.params.type;

    if (this.type !== "add" && this.type !== "view") {
      await this.notFound();
    }

    if (this.type === "edit") {
      if (properties.params.text == null) {
        await this.notFound();
      }
    }

    ["url", "title", "cookie", "origin", "referer", "pwd"].forEach((key) => {
      if (this.isNotBlank(properties.params[key])) {
        this.form[key] = properties.params[key];
      }
    });
    this.ready = true;
  },
  methods: {
    async submit() {
      if (await this.validate()) {
        if (this.type === "add") {
          try {
            const form = this.form;
            const job = await MediaJobService.create(form);
            // console.log("create job : ", job);
            this.close(UpdateEventType.RELOAD);
          } catch (e) {
            this.errorSubmit(e, this.formConvertField);
          }
        } else {
          this.close(UpdateEventType.CONFIRM);
        }
      }
    },
    async deleteItem() {
      const item = this.properties.params.item;
      this.close(UpdateEventType.DELETE, item);
    },
  },
});
